var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"960"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,true)},[_c('span',[_vm._v("Filter(s)")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"raised":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Manage Filters")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.remainingHeaders,"label":"Column","hide-details":"auto"},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1),(['string', 'number'].includes(_vm.filterType))?_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"outlined":"","items":_vm.typeItems,"hide-details":"auto"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(
                  ['string', 'number'].includes(_vm.filterType) &&
                    !['isBlank', 'isNotBlank'].includes(_vm.type)
                )?_c('v-col',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(_vm.type == 'between')?_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto"},model:{value:(_vm.value2),callback:function ($$v) {_vm.value2=$$v},expression:"value2"}})],1):_vm._e()],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.filterType == 'radio')?_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.typeItems,"hide-details":"auto"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e(),(_vm.key != null)?_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"3","align-self":"center"}},[_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":_vm.addFilter}},[_vm._v(" Add "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"color":"indigo","dark":""},on:{"click":_vm.runFilter}},[_vm._v(" Run "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1):_vm._e()],1)],1)],1),(_vm.tempFilters.length)?[_c('v-divider'),_c('v-card-title',[_vm._v(_vm._s(_vm.tempFilters.length > 1 ? "Filters" : "Filter"))]),_c('v-card-text',_vm._l((_vm.tempFilters),function(filter,index){return _c('v-chip',{key:index,staticClass:"mr-2",attrs:{"color":"primary","close":""},on:{"click:close":function($event){return _vm.removeFilter(index)}}},[_vm._v(_vm._s(filter.label))])}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.clearFilters}},[_vm._v("Clear Filters")]),_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":_vm.applyFilters}},[_vm._v("Apply Filters")])],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }