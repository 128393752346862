<template>
  <div>
    <v-toolbar color="grey lighten-2" flat>
      <v-row align="center">
        <v-col cols="auto">
          <h2>Dashboard</h2>
        </v-col>
        <v-col cols="auto">
          <v-icon v-if="!table.dashboard" class="ml-4 loading"
            >mdi-loading</v-icon
          >
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <LeadTypePicker location="dashboard" />
        </v-col>
        <v-col cols="auto">
          <DatePicker location="dashboard" />
        </v-col>
      </v-row>
    </v-toolbar>
    <div v-if="table.dashboard">
      <v-container v-for="platform in platforms" :key="platform.id" fluid>
        <v-card>
          <v-toolbar dark :color="platform.color" flat>
            <v-toolbar-title class="d-flex align-center">
              <v-icon large class="mr-2">{{ platform.icon }}</v-icon>
              {{ platform.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn :to="linkTo(platform.id)" outlined color="white" small
              >View All Data
              <v-icon small class="ml-2">mdi-arrow-right</v-icon></v-btn
            >
          </v-toolbar>

          <div v-for="(group, index) in platform.groups" :key="index">
            <v-divider v-if="index > 0"></v-divider>
            <div class="widget-container">
              <div
                class="widget"
                v-for="(item, index) in group.columns"
                :key="index"
              >
                <Number
                  v-if="group.format === 'number'"
                  :title="headerDetails(item).text"
                  :number="getSum(platform.id, item)"
                />
                <Number
                  v-if="group.format === 'currency'"
                  :title="headerDetails(item).text"
                  :number="getSum(platform.id, item) | currency"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import store from "@/store/";
import { mapState, mapGetters } from "vuex";
import Number from "@/components/dashboard/Number.vue";
import DatePicker from "@/components/DatePicker.vue";
import LeadTypePicker from "@/components/LeadTypePicker.vue";

function formatNumber(value) {
  return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export default {
  components: {
    Number,
    DatePicker,
    LeadTypePicker
  },
  methods: {
    fetchDashboard() {
      store.dispatch("table/fetchDashboard");
    },
    headerDetails(header) {
      return this.getHeader(header);
    },
    getSum(platform, item) {
      let sum = this.table.dashboard[platform][item];
      let output = sum ? formatNumber(sum) : 0;
      if (item === "ctr") {
        output = output + "%";
      }
      return output.toString();
    },
    linkTo(platform) {
      return "/" + platform + "/accounts/";
    }
  },
  created() {
    this.setHashParams();
    this.fetchDashboard();
  },
  computed: {
    ...mapState(["table"]),
    ...mapGetters({
      getHeader: "tableHeaders/getHeader"
    })
  },
  data() {
    return {
      platforms: [
        {
          icon: "mdi-search-web",
          title: "Search Engine Marketing",
          color: "indigo",
          id: "sem",
          groups: [
            {
              format: "number",
              columns: [
                "impressions",
                "clicks",
                "ctr",
                "leads",
                "marketingQualifiedLeads",
                "confirmedMesoLeads",
                "salesQualifiedLeads",
                "customers"
              ]
            },
            {
              format: "currency",
              columns: [
                "cost",
                "cpc",
                "cpl",
                "cpmql",
                "cpcml",
                "cpsql",
                "cpcust"
              ]
            }
          ]
        },
        {
          icon: "mdi-facebook",
          title: "Facebook",
          color: "#4267B2",
          id: "facebook",
          groups: [
            {
              format: "number",
              columns: [
                "impressions",
                "leads",
                "marketingQualifiedLeads",
                "confirmedMesoLeads",
                "salesQualifiedLeads",
                "customers"
              ]
            },
            {
              format: "currency",
              columns: [
                "cost",
                "cpm",
                "cpl",
                "cpmql",
                "cpcml",
                "cpsql",
                "cpcust"
              ]
            }
          ]
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading {
  animation: spinner 0.6s linear infinite;
}
.widget-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2rem 4rem;
  padding: 2rem 2rem;

  & + .widget-container {
    padding-top: 0;
  }
}
</style>
