import AuthService from "@/services/AuthService.js";
import axios from "axios";

export const namespaced = true;

export const state = {
  user: null
};

export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    localStorage.setItem("user", JSON.stringify(userData));
    axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
  },
  CLEAR_USER_DATA() {
    localStorage.removeItem("user");
    location.reload();
  }
};

export const actions = {
  setUser({ commit }, userData) {
    commit("SET_USER_DATA", userData);
  },
  login({ commit }, authCode) {
    return AuthService.getUser(authCode)
      .then(user => {
        commit("SET_USER_DATA", user.data);
      })
      .catch(error => {
        throw error;
      });
  },
  logout({ commit }) {
    commit("CLEAR_USER_DATA");
  }
};

export const getters = {
  userLoggedIn(state) {
    if (state.user != null) {
      if (state.user.roles.includes("ROLE_PAIDPAL")) {
        return true;
      } else {
        return false;
      }
    }
  },
  userIsAdmin(state) {
    if (state.user != null) {
      if (state.user.roles.includes("ROLE_ADMIN")) {
        return true;
      } else {
        return false;
      }
    }
  }
};
