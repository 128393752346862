<template>
  <v-tabs v-if="showSubnav" v-model="active" background-color="transparent">
    <v-tab
      v-for="(tab, index) in tabs"
      :key="index"
      @click="subsectionSelected(tab)"
    >
      {{ tab }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["platform", "section", "subsection"],
  data() {
    return {
      active: null,
      items: {
        sem: {
          accounts: ["campaigns", "adgroups", "keywords", "creatives"],
          campaigns: ["adgroups", "keywords", "creatives"],
          adgroups: ["keywords", "creatives"]
        },
        facebook: {
          accounts: ["campaigns", "adgroups", "ads"],
          campaigns: ["adgroups", "ads"]
        }
      }
    };
  },
  methods: {
    subsectionSelected(subsection) {
      if (subsection != this.table.subsection) {
        this.$router.push({
          name: this.platformSubsectionRoute,
          params: {
            section: this.table.section,
            id: this.table.sectionID,
            subsection: subsection
          }
        });
      }
    }
  },
  mounted() {
    if (this.showSubnav) {
      this.active = this.tabs.indexOf(this.subsection);
    }
  },
  watch: {
    subsection(newSubsection) {
      this.active = this.tabs.indexOf(newSubsection);
    }
  },
  computed: {
    showSubnav() {
      let visibility = this.items[this.platform][this.table.section]
        ? true
        : false;
      return visibility;
    },
    platformSubsectionRoute() {
      return this.platform === "facebook"
        ? "FacebookSubsection"
        : "SemSubsection";
    },
    tabs() {
      return this.items[this.platform][this.table.section];
    },
    ...mapState(["table"])
  }
};
</script>
