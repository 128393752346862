<template>
  <v-navigation-drawer app expand-on-hover width="360">
    <v-toolbar color="indigo" dark flat>
      <div class="d-flex">
        <img src="@/assets/logo/logo-white.svg" class="logo" alt width="24" />
        <img
          src="@/assets/logo/wordmark-white.svg"
          class="wordmark"
          alt
          width="92"
        />
      </div>
    </v-toolbar>
    <v-list>
      <v-list-item to="/dashboard">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>

      <v-list-group
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          :to="child.to"
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Log Out</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="table.status">
        <v-list-item-title
          ><v-subheader class="px-0" inset
            >Last Updated: {{ table.status }}</v-subheader
          ></v-list-item-title
        ></v-list-item
      >
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import router from "@/router";
import { mapState, mapGetters } from "vuex";

export default {
  methods: {
    routeTo(to) {
      router.push({ name: to });
    },
    logout() {
      this.$store.dispatch("user/logout");
    }
  },
  computed: {
    userName() {
      return this.user.firstName + " " + this.user.lastName;
    },
    ...mapState({
      user: state => state.user.user,
      table: state => state.table
    }),
    ...mapGetters("user", ["userIsAdmin"])
  },
  mounted() {
    this.active = "/" + this.table.section;
  },
  data() {
    return {
      active: null,
      drawer: true,
      items: [
        {
          title: "Search Engine Marketing",
          icon: "mdi-search-web",
          to: {
            name: "SemSection",
            params: {
              section: "accounts"
            }
          },
          items: [
            {
              title: "Accounts",
              to: {
                name: "SemSection",
                params: {
                  section: "accounts"
                }
              },
              icon: "mdi-briefcase-outline"
            },
            {
              title: "Campaigns",
              to: {
                name: "SemSection",
                params: {
                  section: "campaigns"
                }
              },
              icon: "mdi-flag-variant-outline"
            },
            {
              title: "Adgroups",
              to: {
                name: "SemSection",
                params: {
                  section: "adgroups"
                }
              },
              icon: "mdi-folder-outline"
            },
            {
              title: "Keywords",
              to: {
                name: "SemSection",
                params: {
                  section: "keywords"
                }
              },
              icon: "mdi-bullseye"
            },
            {
              title: "Creatives",
              to: {
                name: "SemSection",
                params: {
                  section: "creatives"
                }
              },
              icon: "mdi-image-outline"
            }
          ]
        },
        {
          title: "Facebook",
          icon: "mdi-facebook",
          to: {
            name: "FacebookSection",
            params: {
              section: "accounts"
            }
          },
          items: [
            {
              title: "Accounts",
              to: {
                name: "FacebookSection",
                params: {
                  section: "accounts"
                }
              }
            },
            {
              title: "Campaigns",
              to: {
                name: "FacebookSection",
                params: {
                  section: "campaigns"
                }
              }
            },
            {
              title: "Ad Groups",
              to: {
                name: "FacebookSection",
                params: {
                  section: "adgroups"
                }
              }
            },
            {
              title: "Ads",
              to: {
                name: "FacebookSection",
                params: {
                  section: "ads"
                }
              }
            }
          ]
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.wordmark {
  margin-left: 0.5rem;
  opacity: 0;
  transition: opacity 0.25s;

  .v-navigation-drawer--is-mouseover & {
    opacity: 1;
  }
}
</style>
