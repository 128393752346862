<template>
  <div>
    <AppNavigation></AppNavigation>

    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import AppNavigation from "@/components/app/AppNavigation.vue";

export default {
  components: {
    AppNavigation
  }
};
</script>
