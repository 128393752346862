<template>
  <v-container>
    <v-card>
      <v-col class="logo">
        <img src="@/assets/logo/reportaroo-white.svg" alt />
      </v-col>
      <v-col>
        <v-btn @click="signIn" color="grey lighten-4" large>
          <v-icon left>mdi-google</v-icon>Sign In
        </v-btn>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import GAuth from "vue-google-oauth2";

const gauthOption = {
  clientId: process.env.VUE_APP_AUTH_GOOGLE_CLIENTID,
  scope: "profile email",
  prompt: "select_account",
  access_type: "offline"
};

Vue.use(GAuth, gauthOption);
export default {
  methods: {
    signIn() {
      this.$gAuth
        .getAuthCode()
        .then(authCode => {
          this.$store
            .dispatch("user/login", authCode)
            .then(() => {
              this.$router.push({
                name: "SemSection",
                params: { section: "accounts" }
              });
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
.layout--signin {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss" scoped>
.v-card {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 400px;
  max-width: 480px;
  margin: 0 auto;
}
.col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.logo {
  border-radius: 0 !important;
  background-color: #3f51b5;
  img {
    width: 75%;
    max-width: 240px;
  }
}
</style>
