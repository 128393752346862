export const namespaced = true;

export const state = {
  sem: {
    accounts: [
      {
        value: "account",
        text: "Account",
        align: "start",
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "accountId",
      "platform",
      "impressions",
      "clicks",
      "cost",
      "ctr",
      "cvr",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ],
    campaigns: [
      {
        text: "Campaign",
        value: "campaign",
        align: "start",
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "campaignId",
      "platform",
      "account",
      "accountId",
      "status",
      "impressions",
      "clicks",
      "ctr",
      "cvr",
      "cost",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ],
    adgroups: [
      {
        text: "Adgroup",
        value: "adgroup",
        align: "start",
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "adgroupId",
      "platform",
      "account",
      "accountId",
      "campaign",
      "campaignId",
      "status",
      "maxCpc",
      "cpc",
      "impressions",
      "clicks",
      "ctr",
      "cvr",
      "cost",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ],
    keywords: [
      {
        text: "Keyword",
        value: "keyword",
        align: "start",
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "keywordId",
      "platform",
      "account",
      "accountId",
      "campaign",
      "campaignId",
      "adgroup",
      "adgroupId",
      "matchType",
      "status",
      "maxCpc",
      "cpc",
      "impressions",
      "clicks",
      "ctr",
      "cvr",
      "cost",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ],
    creatives: [
      {
        text: "Creative",
        value: "creative",
        align: "start",
        sortable: false,
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "headline1",
      "headline2",
      "headline3",
      "description",
      "url",
      "creativeId",
      "platform",
      "account",
      "accountId",
      "campaign",
      "campaignId",
      "adgroup",
      "adgroupId",
      "status",
      "impressions",
      "clicks",
      "ctr",
      "cvr",
      "cost",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ]
  },
  facebook: {
    accounts: [
      {
        text: "Account",
        value: "account",
        align: "start",
        sortable: false,
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "accountId",
      "platform",
      "impressions",
      "clicks",
      "link_clicks",
      "post_reactions",
      "likes",
      "cost",
      "cpm",
      "ctr",
      "cvr",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ],
    campaigns: [
      {
        text: "Campaign",
        value: "campaign",
        align: "start",
        sortable: false,
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "campaignId",
      "platform",
      "account",
      "accountId",
      "status",
      "impressions",
      "clicks",
      "link_clicks",
      "post_reactions",
      "likes",
      "cost",
      "cpm",
      "ctr",
      "cvr",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ],
    adgroups: [
      {
        text: "Ad Group",
        value: "adgroup",
        align: "start",
        sortable: false,
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "adgroupId",
      "status",
      "platform",
      "account",
      "accountId",
      "campaign",
      "campaignId",
      "impressions",
      "clicks",
      "link_clicks",
      "post_reactions",
      "likes",
      "cost",
      "cpm",
      "ctr",
      "cvr",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ],
    ads: [
      {
        text: "Ad",
        value: "ad",
        align: "start",
        sortable: false,
        filterType: "string",
        total: {
          format: "custom",
          output: "Totals:"
        }
      },
      "adId",
      "status",
      "platform",
      "account",
      "accountId",
      "campaign",
      "campaignId",
      "adgroup",
      "adgroupId",
      "impressions",
      "clicks",
      "link_clicks",
      "post_reactions",
      "likes",
      "cost",
      "cpm",
      "ctr",
      "cvr",
      "mds",
      "cpmd",
      "md2l",
      "leads",
      "cpl",
      "l2mql",
      "marketingQualifiedLeads",
      "cpmql",
      "mql2cml",
      "confirmedMesoLeads",
      "cpcml",
      "cml2sql",
      "salesQualifiedLeads",
      "cpsql",
      "sql2as",
      "accountSendovers",
      "cpas",
      "as2am",
      "accountMeetings",
      "cpam",
      "am2c",
      "customers",
      "cpcust"
    ]
  },
  settings: {
    account: {
      value: "account",
      tooltip: "Name of the paid platform account",
      text: "Account",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    accountId: {
      value: "accountId",
      tooltip: "ID number of the paid platform account",
      text: "Account ID",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    platform: {
      value: "platform",
      tooltip: "Name of the paid advertising platform",
      text: "Platform",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "radio"
    },
    impressions: {
      value: "impressions",
      text: "Impressions",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    clicks: {
      value: "clicks",
      text: "Clicks",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cost: {
      value: "cost",
      tooltip: "Amount Spent<br>(USD)",
      text: "Cost",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    ctr: {
      value: "ctr",
      text: "CTR",
      tooltip: "Click Through Rate",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    mds: {
      value: "mds",
      tooltip: "Marketing Datas",
      text: "MDs",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpmd: {
      value: "cpmd",
      tooltip: "Cost per MD<br>(Cost/MDs)",
      text: "CPMD",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    leads: {
      value: "leads",
      text: "Leads",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpl: {
      value: "cpl",
      tooltip: "Cost per Lead<br>(Cost/Leads)",
      text: "CPL",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    marketingQualifiedLeads: {
      value: "marketingQualifiedLeads",
      text: "MQLs",
      tooltip: "Marketing Qualified Leads",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    confirmedMesoLeads: {
      value: "confirmedMesoLeads",
      text: "CMLs",
      tooltip: "Confirmed Meso Leads",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpcml: {
      value: "cpcml",
      tooltip: "Cost per Confirmed Meso Lead<br>(Cost/CMLs)",
      text: "CPCML",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    salesQualifiedLeads: {
      value: "salesQualifiedLeads",
      tooltip: "Sales Qualified Leads",
      text: "SQLs",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpsql: {
      value: "cpsql",
      text: "CPSQL",
      tooltip: "Cost per Sales Qualified Lead<br>(Cost/SQLs)",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    accountSendovers: {
      value: "accountSendovers",
      tooltip: "Account Sendovers",
      text: "Sendovers",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpas: {
      value: "cpas",
      tooltip: "Cost per Sendover<br>(Cost/Sendovers)",
      text: "CPAS",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    accountMeetings: {
      value: "accountMeetings",
      tooltip: "Account Meetings",
      text: "Meetings",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpam: {
      value: "cpam",
      tooltip: "Cost per Meeting<br>(Cost/Meetings)",
      text: "CPAM",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    customers: {
      value: "customers",
      text: "Customers",
      tooltip: "Customer Value",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpcust: {
      value: "cpcust",
      tooltip: "Cost per Customer<br>(Cost/Customers)",
      text: "CPCust",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    cpmql: {
      value: "cpmql",
      tooltip: "Cost per Marketing Qualified Lead<br>(Cost/MQLs)",
      text: "CPMQL",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    campaignId: {
      value: "campaignId",
      text: "Campaign ID",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    status: {
      value: "status",
      text: "Status",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "radio"
    },
    adgroupId: {
      value: "adgroupId",
      text: "Adgroup ID",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    campaign: {
      value: "campaign",
      text: "Campaign",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    maxCpc: {
      value: "maxCpc",
      tooltip: "Maximum Bid",
      text: "Max CPC",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    cpc: {
      value: "cpc",
      tooltip: "Cost per Click<br/>(Cost/Clicks)",
      text: "CPC",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    keywordId: {
      value: "keywordId",
      text: "Keyword ID",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    adgroup: {
      value: "adgroup",
      text: "Adgroup",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    matchType: {
      value: "matchType",
      text: "Match Type",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "radio"
    },
    headline1: {
      value: "headline1",
      text: "Headline 1",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    headline2: {
      value: "headline2",
      text: "Headline 2",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    headline3: {
      value: "headline3",
      text: "Headline 3",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    description: {
      value: "description",
      text: "Description",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    url: {
      value: "url",
      text: "URL",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    creativeId: {
      value: "creativeId",
      text: "Creative ID",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    adId: {
      value: "adId",
      text: "Ad ID",
      align: "start",
      total: {
        format: "custom",
        output: null
      },
      filterType: "string"
    },
    link_clicks: {
      value: "link_clicks",
      text: "Link Clicks",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    post_reactions: {
      value: "post_reactions",
      text: "Post Reactions",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    likes: {
      value: "likes",
      text: "Likes",
      align: "end",
      total: {
        format: "integer"
      },
      filterType: "number"
    },
    cpm: {
      value: "cpm",
      text: "CPM",
      tooltip: "Cost per Mille<br>(Cost/Impressions x 1000)",
      align: "end",
      total: {
        format: "currency"
      },
      filterType: "number"
    },
    cvr: {
      value: "cvr",
      text: "CVR",
      tooltip: "Click-to-Lead Conversion Rate<br>(Leads/Clicks)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    md2l: {
      value: "md2l",
      text: "MD→L",
      tooltip: "MD-to-Lead Conversion Rate<br>(Leads/MDs)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    l2mql: {
      value: "l2mql",
      text: "L→MQL",
      tooltip:
        "Lead-to-Marketing Qualified Lead<br>Conversion Rate<br>(MQLs/Leads)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    mql2cml: {
      value: "mql2cml",
      text: "MQL→CML",
      tooltip:
        "Marketing Qualified Lead-to-Confirmed Meso Lead<br>Conversion Rate<br>(CMLs/MQLs)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    cml2sql: {
      value: "cml2sql",
      text: "CML→SQL",
      tooltip:
        "Confirmed Meso Lead-to-Sales Qualified Lead<br>Conversion Rate<br>(SQLs/CMLs)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    sql2as: {
      value: "sql2as",
      text: "SQL→AS",
      tooltip:
        "Sales Qualified Lead-to-Sendover<br>Conversion Rate<br>(Sendovers/SQLs)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    as2am: {
      value: "as2am",
      text: "AS→AM",
      tooltip: "Sendover-to-Meeting Conversion Rate<br>(Meetings/Sendovers)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    },
    am2c: {
      value: "am2c",
      text: "AM→C",
      tooltip: "Meeting-to-Customer Conversion Rate<br>(Customers/Meetings)",
      align: "end",
      total: {
        format: null
      },
      filterType: "number"
    }
  }
};

export const getters = {
  getHeader: state => header => {
    return state.settings[header];
  },
  getHeaders: state => (platform, table) => {
    let output = state[platform][table].map(column => {
      if (typeof column === "string") {
        return state.settings[column];
      } else {
        return column;
      }
    });
    return output;
  }
};
