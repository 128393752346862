<template>
  <v-dialog v-model="dialog" width="960">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn large icon v-on="{ ...tooltip, ...dialog }">
            <v-icon>mdi-view-column</v-icon>
          </v-btn>
        </template>
        <span>Column Visibility</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Column Visibility</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col v-if="tablePresets" cols="3">
            <v-list dense shaped nav>
              <v-subheader>Presets</v-subheader>
              <v-list-item
                v-for="(preset, i) in tablePresets"
                :key="i"
                @click="presetSelected(preset)"
              >
                <v-list-item-content>
                  <v-list-item-title v-html="preset.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="9">
            <v-radio-group class="switches">
              <v-switch
                v-for="column in columns"
                :key="column"
                :label="column"
                :value="column"
                hide-details="auto"
                v-model="active"
              ></v-switch>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      active: null
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    active(value) {
      this.$store.dispatch("columnVisibility/setActiveColumnsGroup", [
        this.platform,
        this.tableID,
        value
      ]);
      this.$emit("columnVisibility", value);
    },
    tableItems() {
      this.init();
    }
  },
  methods: {
    init() {
      let activeColumns = this.columnVisibility.active[this.platform][
        this.tableID
      ];
      let defaultPreset = this.columnVisibility.presets[this.platform][
        this.tableID
      ]
        .map(preset => {
          if (preset.name === "Default") {
            return preset.active;
          }
        })
        .flat()
        .filter(function(x) {
          return x !== undefined;
        });

      this.active = activeColumns ? activeColumns : defaultPreset;
    },
    presetSelected(preset) {
      switch (preset.name) {
        case "All":
          this.active = this.columns;
          break;
        case "None":
          this.active = [];
          break;
        default:
          this.active = preset.active;
      }
    }
  },
  computed: {
    platform() {
      return this.$route.meta.platform;
    },
    tableID() {
      let id = [this.table.section, this.table.subsection]
        .join(" ")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
      return id;
    },
    tableItems() {
      return this.table.items;
    },
    tablePresets() {
      return this.columnVisibility.presets[this.platform][this.tableID];
    },
    tableType() {
      return this.table.subsection ? this.table.subsection : this.table.section;
    },
    headers() {
      return this.getHeaders(this.platform, this.tableType);
    },
    columns() {
      let columns = this.headers.map(col => col.text);
      return columns;
    },
    ...mapState(["columnVisibility", "table"]),
    ...mapGetters({
      getHeaders: "tableHeaders/getHeaders"
    })
  }
};
</script>

<style lang="scss">
.switches .v-input--radio-group__input {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
</style>
