<template>
  <v-dialog v-model="dialog" width="960">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn large icon v-on="{ ...tooltip, ...dialog }">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>Filter(s)</span>
      </v-tooltip>
    </template>

    <v-card raised>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Manage Filters</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-row justify="start">
            <v-col cols="3">
              <v-autocomplete
                outlined
                v-model="key"
                :items="remainingHeaders"
                label="Column"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" v-if="['string', 'number'].includes(filterType)">
              <v-row>
                <v-col>
                  <v-select
                    outlined
                    v-model="type"
                    :items="typeItems"
                    hide-details="auto"
                  >
                  </v-select>
                </v-col>

                <v-col
                  v-if="
                    ['string', 'number'].includes(filterType) &&
                      !['isBlank', 'isNotBlank'].includes(type)
                  "
                >
                  <v-row>
                    <v-col>
                      <v-text-field
                        outlined
                        v-model="value"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="type == 'between'">
                      <v-text-field
                        outlined
                        v-model="value2"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col v-if="filterType == 'radio'">
              <v-autocomplete
                outlined
                v-model="value"
                :items="typeItems"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>

            <v-col
              cols="3"
              align-self="center"
              v-if="key != null"
              class="d-flex justify-space-between"
            >
              <v-btn color="success" dark @click="addFilter">
                Add
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
              <v-btn color="indigo" dark @click="runFilter">
                Run
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <template v-if="tempFilters.length">
        <v-divider></v-divider>
        <v-card-title>{{
          tempFilters.length > 1 ? "Filters" : "Filter"
        }}</v-card-title>
        <v-card-text>
          <v-chip
            class="mr-2"
            color="primary"
            v-for="(filter, index) in tempFilters"
            :key="index"
            close
            @click:close="removeFilter(index)"
            >{{ filter.label }}</v-chip
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clearFilters">Clear Filters</v-btn>
          <v-btn color="success" dark @click="applyFilters"
            >Apply Filters</v-btn
          >
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      active: null,
      key: null,
      filterType: null,
      type: null,
      value: null,
      tempFilters: [],
      filterItems: {
        string: [
          { text: "Contains", value: "contains" },
          { text: "Does Not Contain", value: "doesNotContain" },
          { text: "Equals", value: "equals" },
          { text: "Is Blank", value: "isBlank" },
          { text: "Is Not Blank", value: "isNotBlank" }
        ],
        number: [
          { text: "Equals", value: "=" },
          { text: "Does Not Equal", value: "!=" },
          { text: "Greater Than", value: ">" },
          { text: "Greater Than or Equal To", value: ">" },
          { text: "Less Than", value: "<" },
          { text: "Less Than or Equal To", value: "<=" },
          { text: "Between", value: "between" }
        ]
      }
    };
  },
  watch: {
    key(value) {
      if (value !== null) {
        let header = this.remainingHeaders.find(
          header => header.value == value
        );
        this.filterType = header.filterType;
        this.type = this.getDefaultType;
      } else {
        this.filterType = null;
        this.type = null;
      }
      this.value = null;
      this.value2 = null;
    },
    dialog(visible) {
      if (visible) {
        this.tempFilters = [...this.table.filters];
      } else {
        this.tempFilters = [];
      }
    }
  },
  methods: {
    runFilter() {
      this.addFilter();
      this.applyFilters();
      this.dialog - false;
    },
    applyFilters() {
      this.$store.dispatch("table/setFilters", this.tempFilters);
      this.$store.dispatch("table/fetchTable");
      this.dialog = false;
    },
    clearFilters() {
      this.tempFilters = [];
      this.$store.dispatch("table/setFilters", []);
      this.$store.dispatch("table/fetchTable");
      this.dialog = false;
    },
    removeFilter(index) {
      this.tempFilters.splice(index, 1);
    },
    resetForm() {
      this.key = null;
      this.filterType = null;
      this.type = null;
      this.value = null;
    },
    addFilter() {
      let error = false;
      let filter = {
        key: this.key,
        type: this.type,
        value: this.value
      };

      if (this.filterType == "number" && this.type == "between") {
        if (this.value !== null && this.value2 !== null) {
          filter.value = {
            start: this.value,
            end: this.value2
          };
        } else {
          error = "Please add a value";
        }
      }

      if (this.filterType == "radio") {
        filter.type = "equals";
      }

      if (filter.value === null) {
        if (
          !(
            this.filterType == "string" &&
            ["isBlank", "isNotBlank"].includes(this.type)
          )
        ) {
          error = "Please add a value";
        }
      }

      filter["label"] = this.getFilterLabel(filter);

      if (!error) {
        this.tempFilters.push(filter);
        this.resetForm();
      } else {
        console.log(error);
      }
    },
    getFilterLabel(filter) {
      let header = this.headers.find(header => header.value == filter.key);
      let name = header.text;
      let type = filter.type;
      let value = filter.value;

      if (header.filterType == "string") {
        let filterItem = this.filterItems[header.filterType].find(
          item => item.value == filter.type
        );
        type = filterItem.text;
        value = value !== null ? '"' + value + '"' : null;
      }

      if (header.filterType == "radio") {
        type = "=";
      }

      if (type == "between") {
        value = filter.value["start"] + " & " + filter.value["end"];
      }

      return [name, type.toLowerCase(), value].join(" ");
    }
  },
  computed: {
    platform() {
      return this.$route.meta.platform;
    },
    getDefaultType() {
      switch (this.filterType) {
        case "string":
          return "contains";
        case "number":
          return "=";
        default:
          return null;
      }
    },
    typeItems() {
      let items = [];

      switch (this.filterType) {
        case "string":
          items = this.filterItems.string;
          break;
        case "number":
          items = this.filterItems.number;
          break;
        case "radio":
          this.table.items.forEach(item => {
            if (!items.includes(item[this.key])) {
              items.push(item[this.key]);
            }
          });
          break;
      }
      return items;
    },
    showStringFilterInput() {
      return (
        this.stringFilterSelect != "isBlank" &&
        this.stringFilterSelect != "isNotBlank"
      );
    },
    tableType() {
      return this.table.subsection ? this.table.subsection : this.table.section;
    },
    headers() {
      return this.getHeaders(this.platform, this.tableType);
    },
    remainingHeaders() {
      let filteredHeaders = this.tempFilters.map(filter => {
        return filter.key;
      });
      let remainingHeaders = this.headers.filter(header => {
        return filteredHeaders.indexOf(header.value) === -1;
      });

      return remainingHeaders;
    },
    ...mapState(["table"]),
    ...mapGetters({
      getHeaders: "tableHeaders/getHeaders"
    })
  }
};
</script>

<style></style>
