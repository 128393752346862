<template>
  <div>
    <v-chip
      class="mr-2 my-2"
      color="primary"
      v-for="(filter, index) in filters"
      :key="index"
      close
      @click:close="removeFilterByIndex(index)"
      >{{ filter.label }}</v-chip
    >
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  methods: {
    getFilterLabel(filter) {
      let header = this.headers.find(header => header.value == filter.key);
      let name = header.text;
      let type = filter.type;
      let value = filter.value;

      if (header.filterType == "string") {
        let filterItem = this.filterItems[header.filterType].find(
          item => item.value == filter.type
        );
        type = filterItem.text;
        value = value !== null ? '"' + value + '"' : null;
      }

      if (header.filterType == "radio") {
        type = "=";
      }

      if (type == "between") {
        value = filter.value["start"] + " & " + filter.value["end"];
      }

      return [name, type.toLowerCase(), value].join(" ");
    },
    removeFilterByIndex(index) {
      this.$store.dispatch("table/removeFilterByIndex", index);
      this.$store.dispatch("table/fetchTable");
    }
  },
  computed: {
    tableType() {
      return this.table.subsection ? this.table.subsection : this.table.section;
    },
    headers() {
      return this.getHeaders(this.tableType);
    },
    ...mapState(["table"]),
    ...mapGetters({
      filters: "table/filters",
      getHeaders: "tableHeaders/getHeaders"
    })
  }
};
</script>

<style></style>
