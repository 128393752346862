<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    max-width="480px"
    min-width="480px"
  >
    <template v-slot:activator="{ on }">
      <v-card flat v-on="on" color="grey lighten-5" class="grey--text">
        <v-list-item class="px-4">
          <v-list-item-content class="">
            <v-list-item-title
              >Lead Types: <span>{{ selectedLabel }}</span></v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-icon class="ml-2 my-3">
            <v-icon>mdi-chevron-down</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-card>
    </template>

    <v-card class="lt-datepicker" raised>
      <v-card-text>
        <v-row dense>
          <v-col v-for="(type, index) in types" :key="index" cols="6">
            <v-checkbox
              v-model="selected"
              :label="type"
              :value="type"
              hide-details="true"
              >{{ type }}</v-checkbox
            >
          </v-col>
        </v-row>
        <v-row v-if="error">
          <v-col>
            <v-alert dense text type="error"
              >At least one lead type must be selected.</v-alert
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn text @click="menu = false">Cancel</v-btn>
            <v-btn class="ml-2" color="primary" :disabled="error" @click="apply"
              >Apply</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    location: {
      type: String,
      default: "table"
    }
  },
  data() {
    return {
      menu: false,
      error: false,
      selected: []
    };
  },
  computed: {
    selectedLabel() {
      if (this.allTypesSelected) {
        return "All Selected";
      } else {
        return this.active.length + " of " + this.types.length + " Selected";
      }
    },
    allTypesSelected() {
      return this.active.length === this.types.length;
    },
    ...mapState({
      types: state => state.leadTypes.types
    }),
    ...mapGetters({
      active: "leadTypes/getActive"
    })
  },
  mounted() {
    this.selected = this.active;
  },
  watch: {
    selected(selectedVal) {
      if (selectedVal.length === 0) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
    menu(status) {
      if (status === true) {
        this.selected = this.active;
      }
    }
  },
  methods: {
    setActiveLeadTypes() {
      this.$store.dispatch("leadTypes/setActiveLeadTypes", this.selected);
    },
    apply() {
      if (!this.error) {
        this.setActiveLeadTypes();

        if (this.location === "table") {
          this.$store.dispatch("table/fetchTable");
        } else if (this.location === "dashboard") {
          this.$store.dispatch("table/fetchDashboard");
        }

        this.menu = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
