<template>
  <div class="big-number">
    <div class="big-number__title grey--text text--darken-3">{{ title }}</div>
    <div
      class="big-number__number grey--text text--darken-4"
      :class="numberClass"
    >
      {{ number }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    number: String
  },
  computed: {
    numberClass() {
      return this.number && this.number.startsWith("$") ? "small" : "large";
    }
  }
};
</script>

<style lang="scss" scoped>
.big-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.big-number__title {
  font-size: 0.875rem;
  line-height: 1.125;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.big-number__number {
  font-weight: 900;
  line-height: 1.5;

  &.small {
    font-size: 2rem;
  }
  &.large {
    font-size: 2.5rem;
  }
}
</style>
