<template>
  <v-app
    id="app"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <component :is="layout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
import store from "@/store/";

export default {
  created() {
    store.dispatch("table/fetchStatus");
    store.dispatch("leadTypes/fetchLeadTypes");
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "Default") + "-layout";
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  },
  name: "App"
};
</script>

<style lang="scss">
:root {
  // Theme color pallet
  --primaryColor--light: #c5cae9;
  --primaryColor: #3f51b5;
  --primaryColor--dark: #1a237e;
  --secondaryColor--light: #d3d0f0;
  --secondaryColor: #847dd4;
  --secondaryColor--dark: #615590;
  --accentColor--light: #fbbfb1;
  --accentColor: #f34e26;
  --accentColor--dark: #b3351a;
  --extraColor1--light: #a5bed2;
  --extraColor1: #064a83;
  --extraColor1--dark: #021a2f;
  --extraColor2--light: #fbb7b7;
  --extraColor2: #f53737;
  --extraColor2--dark: #b42525;
  --extraColor3--light: #fee0a3;
  --extraColor3: #fda800;
  --extraColor3--dark: #ba7200;

  // Status colors
  --successColor--light: #e1efb2;
  --successColor: #abd22a;
  --successColor--dark: #7e8f1d;
  --errorColor--light: #fbb7b7;
  --errorColor: #f53737;
  --errorColor--dark: #b42525;
  --warningColor--light: #fee0a3;
  --warningColor: #fda800;
  --warningColor--dark: #ba7200;

  // Text colors
  --linkColor: #f34e26;
  --linkColor--hover: #b3351a;
  --textColor--light: #ced2d6;
  --textColor: #798490;
  --textColor--dark: #392f33;

  // Background
  --backgroundColor: #f5f5f5;

  // Spacing variable
  --gutter: 0.5rem;
}

@import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&display=swap");

body .v-application {
  font-family: "Lato", sans-serif;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.loading-icon {
  animation: rotate 1.5s linear infinite;
}
</style>
