var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[(_vm.platformIcon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.platformIcon.color}},[_vm._v(_vm._s(_vm.platformIcon.icon))]):_vm._e(),(_vm.sectionID === null)?_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.sectionRouteName,
          params: {
            section: 'accounts'
          }
        }}},[_vm._v(" "+_vm._s(_vm.platformName)+" ")])],1),_c('li',[_vm._v(_vm._s(_vm.capitalize(_vm.section)))])]):_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.sectionRouteName,
          params: {
            section: 'accounts'
          }
        }}},[_vm._v(" "+_vm._s(_vm.platformName)+" ")])],1),(_vm.table.details !== null)?_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.subsectionRouteName,
          params: {
            section: 'accounts',
            id: _vm.table.details.account_id,
            subsection: 'campaigns'
          }
        }}},[_vm._v(" "+_vm._s(_vm.table.details.account)+" ")])],1):_vm._e(),(
        _vm.table.details !== null &&
          ['campaigns', 'adgroups'].includes(_vm.table.section)
      )?_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.subsectionRouteName,
          params: {
            section: 'campaigns',
            id: _vm.table.details.campaign_id,
            subsection: 'adgroups'
          }
        }}},[_vm._v(" "+_vm._s(_vm.table.details.campaign)+" ")])],1):_vm._e(),(_vm.table.details !== null && ['adgroups'].includes(_vm.table.section))?_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.subsectionRouteName,
          params: {
            section: 'adgroups',
            id: _vm.table.details.adgroupId,
            subsection: 'keywords'
          }
        }}},[_vm._v(" "+_vm._s(_vm.table.details.adgroup)+" ")])],1):_vm._e()]),(_vm.table.details === null && _vm.sectionID)?_c('v-icon',{staticClass:"loading-icon ml-4"},[_vm._v("mdi-loading")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }