import axios from "axios";
import store from "@/store";
// import router from "@/router";

function getConfig() {
  let config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "X-AUTH-TOKEN": store.state.user.user.token
    },
    baseURL: process.env.VUE_APP_API_REPORTAROO
  };
  return config;
}

function getDates() {
  let dates = {};
  dates["start"] = store.state.dates.initial.start;
  dates["end"] = store.state.dates.initial.end;
  return dates;
}

function getActiveLeadTypes() {
  return store.getters["leadTypes/getActiveQueryParams"];
}

function getKey(section) {
  switch (section) {
    case "accounts":
      return "accountId";
    case "campaigns":
      return "campaignId";
    case "adgroups":
      return "adgroupId";
    default:
      return null;
  }
}

function getAPI(path) {
  return axios.get(path, getConfig());
}

function postAPI(path, body = null) {
  let data = body ? body : getBody();
  let options = getConfig();
  return axios.post(path, data, options);
}

function getFiltersArray() {
  let fieldsArray = [];

  // add fields from table filters
  let stateFilters = store.state.table.filters;

  console.log(...stateFilters);

  if (stateFilters.length) {
    fieldsArray = fieldsArray.concat(stateFilters);
  }

  //add the section filter
  if (store.state.table.subsection) {
    let sectionFilter = {
      key: getKey(store.state.table.section),
      type: "=",
      value: store.state.table.sectionID
    };

    fieldsArray.push(sectionFilter);
  }
  return fieldsArray;
}

function getBody() {
  let body = {};

  //add all fields to filters
  body["fields"] = getFiltersArray();

  //add dates
  body["date"] = getDates();

  //add dates
  let leadTypes = getActiveLeadTypes();
  if (leadTypes) {
    body["lead_types"] = leadTypes;
  }

  return body;
}

export default {
  getSemAccounts() {
    return postAPI("/sem/accounts/");
  },
  getSemCampaigns() {
    return postAPI("/sem/campaigns/");
  },
  getSemAdgroups() {
    return postAPI("/sem/adgroups/");
  },
  getSemKeywords() {
    return postAPI("/sem/keywords/");
  },
  getSemCreatives() {
    return postAPI("/sem/creatives/");
  },
  getFacebookAccounts() {
    return postAPI("/facebook/accounts/");
  },
  getFacebookCampaigns() {
    return postAPI("/facebook/campaigns/");
  },
  getFacebookAdgroups() {
    return postAPI("/facebook/adgroups/");
  },
  getFacebookAds() {
    return postAPI("/facebook/ads/");
  },
  getDashboard() {
    return postAPI("/dashboard/accounts/");
  },
  getRow(platform, filters) {
    let path = "/".concat(platform, "/row/");
    return postAPI(path, filters);
  },
  getStatus() {
    return getAPI("/dashboard/status");
  },
  getLeadTypes() {
    return getAPI("/dashboard/lead_types");
  }
};
