<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on: tooltip }">
      <download-csv :data="table.items || []">
        <v-btn :disabled="!table.items" large icon v-on="tooltip">
          <v-icon>mdi-file-download-outline</v-icon>
        </v-btn>
      </download-csv>
    </template>
    <span>Download CSV</span>
  </v-tooltip>
</template>

<script>
import { mapState } from "vuex";
import JsonCSV from "vue-json-csv";

export default {
  components: {
    downloadCsv: JsonCSV
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["table"])
  }
};
</script>

<style></style>
