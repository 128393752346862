import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import SignIn from "../views/SignIn.vue";
import Table from "../views/Table.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      meta: {
        layout: "Dashboard"
      },
      component: Dashboard
    },
    {
      path: "/sign-in",
      name: "SignIn",
      meta: {
        layout: "SignIn"
      },
      component: SignIn
    },
    {
      path: "/sem/:section/:id/:subsection",
      name: "SemSubsection",
      props: true,
      component: Table,
      meta: {
        platform: "sem",
        requiresAuth: true
      }
    },
    {
      path: "/sem/:section",
      name: "SemSection",
      props: true,
      component: Table,
      meta: {
        platform: "sem",
        requiresAuth: true
      }
    },
    {
      path: "/facebook/:section/:id/:subsection",
      name: "FacebookSubsection",
      props: true,
      component: Table,
      meta: {
        platform: "facebook",
        requiresAuth: true
      }
    },
    {
      path: "/facebook/:section",
      name: "FacebookSection",
      props: true,
      component: Table,
      meta: {
        platform: "facebook",
        requiresAuth: true
      }
    },
    {
      path: "*",
      name: "404"
    }
  ]
});

router.beforeEach((to, from, next) => {
  //check to see if user is not logged in
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next("/sign-in");
  } else {
    next();
  }
});

export default router;
