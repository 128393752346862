<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    max-width="480px"
    min-width="480px"
  >
    <template v-slot:activator="{ on }">
      <v-card flat v-on="on" color="grey lighten-5" class="grey--text">
        <v-list-item class="px-4">
          <v-list-item-content class="">
            <v-list-item-title>{{ displayInitial }}</v-list-item-title>
            <v-list-item-subtitle v-if="compareEnabled">
              Compared To: {{ displayCompare }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon class="ml-2 my-3">
            <v-icon>mdi-chevron-down</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-card>
    </template>

    <v-card class="lt-datepicker" raised>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="preset"
              label="Dates:"
              hide-details="auto"
              outlined
              dense
              :items="presetOptions"
              @change="presetSelected"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-menu
              ref="initialStartMenu"
              v-model="initialStartMenu"
              :close-on-content-click="false"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="initialStartFormatted"
                  label="Start Date: "
                  placeholder="MM/DD/YYYY"
                  persistent-hint
                  clearable
                  outlined
                  dense
                  hide-details="auto"
                  v-on:keyup.enter="
                    initialStart = parseDate(initialStartFormatted)
                  "
                  @blur="initialStart = parseDate(initialStartFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="initialStart"
                no-title
                color="indigo"
                @input="initialStartMenu = false"
                @click:date="preset = 'Custom'"
                :min="parseDate(dates.earliest)"
                :max="
                  initialEnd ? parseDate(initialEnd) : parseDate(dates.today)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              ref="initialEndMenu"
              v-model="initialEndMenu"
              :close-on-content-click="false"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="initialEndFormatted"
                  label="End Date: "
                  placeholder="MM/DD/YYYY"
                  persistent-hint
                  clearable
                  outlined
                  dense
                  hide-details="auto"
                  v-on:keyup.enter="initialEnd = parseDate(initialEndFormatted)"
                  @blur="initialEnd = parseDate(initialEndFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="initialEnd"
                no-title
                color="indigo"
                @input="initialEndMenu = false"
                @click:date="preset = 'Custom'"
                :min="
                  initialStart
                    ? parseDate(initialStart)
                    : parseDate(dates.earliest)
                "
                :max="parseDate(dates.today)"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- 
        <v-divider class="my-4"></v-divider>
        <v-row>
          <v-col>
            <v-select
              v-model="compareTo"
              outlined
              dense
              color="deep-orange"
              label="Compare To: "
              :items="compareToOptions"
              @change="compareSelected"
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="compareTo != 'None'">
          <v-col>
            <v-menu
              ref="compareStartMenu"
              v-model="compareStartMenu"
              :close-on-content-click="false"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="compareStartFormatted"
                  label="Compare Start Date: "
                  placeholder="MM/DD/YYYY"
                  persistent-hint
                  outlined
                  clearable
                  dense
                  color="deep-orange"
                  hide-details="auto"
                  v-on:keyup.enter="
                    compareStart = parseDate(compareStartFormatted)
                  "
                  @blur="compareStart = parseDate(compareStartFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="compareStart"
                no-title
                color="deep-orange "
                @input="compareStartMenu = false"
                @click:date="compareTo = 'Custom'"
                :min="parseDate(dates.earliest)"
                :max="
                  compareEnd ? parseDate(compareEnd) : parseDate(dates.today)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              ref="compareEndMenu"
              v-model="compareEndMenu"
              :close-on-content-click="false"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="compareEndFormatted"
                  label="Compare End Date: "
                  placeholder="MM/DD/YYYY"
                  persistent-hint
                  outlined
                  clearable
                  dense
                  color="deep-orange"
                  hide-details="auto"
                  v-on:keyup.enter="compareEnd = parseDate(compareEndFormatted)"
                  @blur="compareEnd = parseDate(compareEndFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="compareEnd"
                no-title
                color="deep-orange "
                @input="compareEndMenu = false"
                @click:date="compareTo = 'Custom'"
                :min="
                  compareStart
                    ? parseDate(compareStart)
                    : parseDate(dates.earliest)
                "
                :max="parseDate(dates.today)"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col class="text-right">
            <v-btn text @click="menu = false">Cancel</v-btn>
            <v-btn
              class="ml-2"
              color="primary"
              @click="
                setDates();
                menu = false;
              "
              >Apply</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: {
    location: {
      type: String,
      default: "table"
    }
  },
  data() {
    return {
      menu: false,
      initialStartMenu: false,
      initialEndMenu: false,
      compareStartMenu: false,
      compareEndMenu: false,
      preset: null,
      dateFormat: {
        format: "MM/DD/YYYY",
        parse: "YYYY-MM-DD",
        display: "MMM D, YYYY"
      },
      presetOptions: [
        "Custom",
        "Yesterday",
        "Last Week",
        "This Month",
        "This Quarter",
        "Last Month",
        "Last Quarter",
        "Past 7 Days",
        "Past 30 Days",
        "Past 60 Days",
        "Past 90 Days",
        "This Year",
        "All-Time"
      ],
      compareTo: "None",
      compareToOptions: [
        "None",
        "Previous Period",
        "Previous Week",
        "Previous Month",
        "Previous Quarter",
        "Previous Year",
        "Custom"
      ],

      initialStart: null,
      initialEnd: null,
      compareStart: null,
      compareEnd: null,

      initialStartFormatted: null,
      initialEndFormatted: null,
      compareStartFormatted: null,
      compareEndFormatted: null
    };
  },
  computed: {
    compareEnabled() {
      return this.dates.compare.enabled;
    },
    displayInitial() {
      return this.dates.initial.start != this.dates.initial.end
        ? this.displayDate(this.dates.initial.start) +
            " - " +
            this.displayDate(this.dates.initial.end)
        : this.displayDate(this.dates.initial.start);
    },
    displayCompare() {
      return this.dates.compare.start != this.dates.compare.end
        ? this.displayDate(this.dates.compare.start) +
            " - " +
            this.displayDate(this.dates.compare.end)
        : this.displayDate(this.dates.compare.start);
    },
    ...mapState(["dates"])
  },
  created() {},
  mounted() {
    this.preset = this.dates.initial.preset;
    this.initialStart = this.dates.initial.start;
    this.preinitialEndset = this.dates.initial.end;
    this.presetSelected();
  },
  watch: {
    initialStart(val) {
      this.initialStartFormatted = this.formatDate(val);
      this.compareSelected();
    },
    initialEnd(val) {
      this.initialEndFormatted = this.formatDate(val);
      this.compareSelected();
    },
    compareStart(val) {
      this.compareStartFormatted = this.formatDate(val);
    },
    compareEnd(val) {
      this.compareEndFormatted = this.formatDate(val);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      return moment(date).format(this.dateFormat.format);
    },
    parseDate(date) {
      if (!date) return null;
      return moment(date).format(this.dateFormat.parse);
    },
    displayDate(date) {
      if (!date) return null;
      return moment(date).format(this.dateFormat.display);
    },
    formatDates(dates) {
      if (!dates) return null;
      let formatted = dates.map(date => {
        return this.formatDate(date);
      });
      return formatted.join(" — ");
    },
    displayDates(dates) {
      if (!dates) return null;
      let displayed = dates.map(date => {
        return this.displayDate(date);
      });
      return displayed.join(" — ");
    },
    subtractDays(count, date) {
      return moment(date)
        .subtract(count, "days")
        .format(this.dateFormat.data);
    },
    presetSelected() {
      let start = null;
      let end = this.dates.yesterday;

      this.$store.dispatch("dates/setInitialPreset", this.preset);

      switch (this.preset) {
        case "Custom":
          start = this.dates.initial.start;
          end = this.dates.initial.end;
          break;
        case "Yesterday":
          start = this.dates.yesterday;
          break;
        case "Last Week":
          var lastWeek = moment().subtract(1, "weeks");
          start = this.parseDate(lastWeek.startOf("week"));
          end = this.parseDate(lastWeek.endOf("week"));
          break;
        case "This Month":
          start = this.parseDate(moment().startOf("month"));
          end = this.dates.today;
          break;
        case "This Quarter":
          start = this.parseDate(moment().startOf("Quarter"));
          end = this.dates.today;
          break;
        case "Last Month":
          var lastMonth = moment().subtract(1, "months");
          start = this.parseDate(lastMonth.startOf("month"));
          end = this.parseDate(lastMonth.endOf("month"));
          break;
        case "Last Quarter":
          var lastQuarter = moment().subtract(1, "quarters");
          start = this.parseDate(lastQuarter.startOf("quarter"));
          end = this.parseDate(lastQuarter.endOf("quarter"));
          break;
        case "Past 7 Days":
          start = this.subtractDays(6, end);
          break;
        case "Past 30 Days":
          start = this.parseDate(this.subtractDays(29, end));
          break;
        case "Past 60 Days":
          start = this.subtractDays(59, end);
          break;
        case "Past 90 Days":
          start = this.subtractDays(89, end);
          break;
        case "This Year":
          start = this.parseDate(moment().startOf("year"));
          end = this.dates.today;
          break;
        case "All-Time":
          start = "2017-01-01";
          end = this.dates.today;
          break;
      }

      this.initialStart = start;
      this.initialEnd = end;
    },
    compareSelected() {
      let start = null;
      let end = null;

      switch (this.compareTo) {
        case "Previous Period":
          var duration = moment(this.initialEnd).diff(
            moment(this.initialStart),
            "days"
          );
          end = this.parseDate(moment(this.initialStart).subtract(1, "days"));
          start = this.parseDate(moment(end).subtract(duration, "days"));
          break;
        case "Previous Week":
          if (this.initialStart) {
            start = this.parseDate(
              moment(this.initialStart).subtract(1, "week")
            );
          }
          if (this.initialEnd) {
            end = this.parseDate(moment(this.initialEnd).subtract(1, "week"));
          }
          break;
        case "Previous Month":
          if (this.initialStart) {
            start = this.parseDate(
              moment(this.initialStart).subtract(1, "months")
            );
          }
          if (this.initialEnd) {
            end = this.parseDate(moment(this.initialEnd).subtract(1, "months"));
          }
          break;
        case "Previous Quarter":
          if (this.initialStart) {
            start = this.parseDate(
              moment(this.initialStart).subtract(1, "quarters")
            );
          }
          if (this.initialEnd) {
            end = this.parseDate(
              moment(this.initialEnd).subtract(1, "quarters")
            );
          }
          break;
        case "Previous Year":
          if (this.initialStart) {
            start = this.parseDate(
              moment(this.initialStart).subtract(1, "years")
            );
          }
          if (this.initialEnd) {
            end = this.parseDate(moment(this.initialEnd).subtract(1, "years"));
          }
          break;
        case "Custom":
          start = this.compareStart;
          end = this.compareEnd;
          break;
      }

      this.compareStart = start;
      this.compareEnd = end;
    },
    setDates() {
      if (
        moment(this.initialStart).isValid() &&
        moment(this.initialEnd).isValid()
      ) {
        this.$store.dispatch("dates/setInitial", [
          this.initialStart,
          this.initialEnd
        ]);
        this.$store.dispatch("dates/setInitialPreset", this.preset);
        this.$emit("datesChanged");

        if (
          moment(this.compareStart).isValid() &&
          moment(this.compareEnd).isValid()
        ) {
          this.$store.dispatch("dates/setCompare", [
            this.compareStart,
            this.compareEnd
          ]);
        } else {
          this.$store.dispatch("dates/setCompare", null);
        }

        if (this.location === "table") {
          this.$store.dispatch("table/fetchTable");
        } else if (this.location === "dashboard") {
          this.$store.dispatch("table/fetchDashboard");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item__content {
  display: block;
}
</style>
<style lang="scss">
.lt-datepicker .v-icon.mdi-close {
  font-size: 16px;
}
</style>
