export const namespaced = true;

export const state = {
  presets: {
    sem: {
      accounts: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Account",
            "Platform",
            "Cost",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      accountsCampaigns: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Campaign",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      accountsAdgroups: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Adgroup",
            "Campaign",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      accountsKeywords: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Keyword",
            "Match Type",
            "Platform",
            "Account",
            "Campaign",
            "Adgroup",
            "Status",
            "Max CPC",
            "CPC",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      accountsCreatives: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Creative",
            "Campaign",
            "Adgroup",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      campaigns: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Campaign",
            "Platform",
            "Account",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      campaignsAdgroups: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Adgroup",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      campaignsKeywords: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Keyword",
            "Adgroup",
            "Status",
            "Max CPC",
            "CPC",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      campaignsCreatives: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Creative",
            "Adgroup",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      adgroups: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Adgroup",
            "Platform",
            "Account",
            "Campaign",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      adgroupsKeywords: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Keyword",
            "Match Type",
            "Status",
            "Max CPC",
            "CPC",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      adgroupsCreatives: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Creative",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      keywords: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Keyword",
            "Match Type",
            "Platform",
            "Account",
            "Campaign",
            "Adgroup",
            "Status",
            "Max CPC",
            "CPC",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      creatives: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Creative",
            "Platform",
            "Account",
            "Campaign",
            "Adgroup",
            "Status",
            "Impressions",
            "Clicks",
            "CTR",
            "Cost",
            "MDs",
            "CPMD",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ]
    },
    facebook: {
      accounts: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Account",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Cost",
            "Cost per Mile",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "Cost per Mille",
            "CPCust"
          ]
        },
        {
          name: "None"
        }
      ],
      accountsCampaigns: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Status",
            "Impressions",
            "Clicks",
            "Cost",
            "CTR",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust",
            "Post Reactions",
            "Link Clicks",
            "Likes",
            "Cost per Mille",
            "Campaign"
          ]
        },
        {
          name: "None"
        }
      ],
      accountsAdgroups: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Ad Group",
            "Campaign",
            "CMLs",
            "Cost",
            "CPCML",
            "CPCust",
            "CPL",
            "CPMQL",
            "CPSQL",
            "CTR",
            "Customers",
            "Leads",
            "MQLs",
            "SQLs",
            "Status",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Cost per Mille",
            "Impressions",
            "Clicks"
          ]
        },
        {
          name: "None"
        }
      ],
      accountsAds: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Ad",
            "Adgroup",
            "Campaign",
            "CMLs",
            "Cost",
            "CPCML",
            "CPCust",
            "CPL",
            "CPMQL",
            "CPSQL",
            "Customers",
            "Leads",
            "MQLs",
            "SQLs",
            "Cost per Mille",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Status",
            "Impressions"
          ]
        },
        {
          name: "None"
        }
      ],
      campaigns: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Status",
            "Impressions",
            "Clicks",
            "Cost",
            "CTR",
            "Leads",
            "CPL",
            "MQLs",
            "CPMQL",
            "CMLs",
            "CPCML",
            "SQLs",
            "CPSQL",
            "Customers",
            "CPCust",
            "Post Reactions",
            "Link Clicks",
            "Likes",
            "Cost per Mille",
            "Campaign"
          ]
        },
        {
          name: "None"
        }
      ],
      campaignsAdgroups: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Ad Group",
            "CMLs",
            "Cost",
            "CPCML",
            "CPCust",
            "CPL",
            "CPMQL",
            "CPSQL",
            "CTR",
            "Customers",
            "Leads",
            "MQLs",
            "SQLs",
            "Status",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Cost per Mille",
            "Impressions",
            "Clicks"
          ]
        },
        {
          name: "None"
        }
      ],
      campaignsAds: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Ad",
            "Adgroup",
            "CMLs",
            "Cost",
            "CPCML",
            "CPCust",
            "CPL",
            "CPMQL",
            "CPSQL",
            "Customers",
            "Leads",
            "MQLs",
            "SQLs",
            "Cost per Mille",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Status",
            "Impressions"
          ]
        },
        {
          name: "None"
        }
      ],
      adgroups: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Ad Group",
            "CMLs",
            "Cost",
            "CPCML",
            "CPCust",
            "CPL",
            "CPMQL",
            "CPSQL",
            "CTR",
            "Customers",
            "Leads",
            "MQLs",
            "SQLs",
            "Status",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Cost per Mille",
            "Impressions",
            "Clicks"
          ]
        },
        {
          name: "None"
        }
      ],
      adgroupsAds: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Ad",
            "CMLs",
            "Cost",
            "CPCML",
            "CPCust",
            "CPL",
            "CPMQL",
            "CPSQL",
            "CTR",
            "Customers",
            "Leads",
            "MQLs",
            "SQLs",
            "Status",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Cost per Mille",
            "Impressions",
            "Clicks"
          ]
        },
        {
          name: "None"
        }
      ],
      ads: [
        {
          name: "All"
        },
        {
          name: "Default",
          active: [
            "Ad",
            "Account",
            "Adgroup",
            "Campaign",
            "CMLs",
            "Cost",
            "CPCML",
            "CPCust",
            "CPL",
            "CPMQL",
            "CPSQL",
            "Customers",
            "Leads",
            "MQLs",
            "SQLs",
            "Cost per Mille",
            "Link Clicks",
            "Post Reactions",
            "Likes",
            "Status",
            "Impressions"
          ]
        },
        {
          name: "None"
        }
      ]
    }
  },
  active: {
    sem: {
      accounts: null,
      accountsCampaigns: null,
      accountsAdgroups: null,
      accountsKeywords: null,
      accountsCreatives: null,
      campaigns: null,
      campaignsAdgroups: null,
      campaignsKeywords: null,
      campaignsCreatives: null,
      adgroups: null,
      adgroupsKeywords: null,
      adgroupsCreatives: null,
      keywords: null,
      creatives: null
    },
    facebook: {
      accounts: null,
      accountsCampaigns: null,
      accountsAdgroups: null,
      accountsAds: null,
      campaigns: null,
      campaignsAdgroups: null,
      campaignsAds: null,
      adgroups: null,
      adgroupsAds: null,
      ads: null
    }
  }
};

export const mutations = {
  SET_ACTIVE_COLUMNS_GROUP(state, [platform, group, val]) {
    state.active[platform][group] = val;
    localStorage.setItem("columnVisibility", JSON.stringify(state.active));
  },
  SET_ACTIVE_COLUMNS(state, val) {
    state.active = val;
    localStorage.setItem("columnVisibility", JSON.stringify(state.active));
  }
};

export const actions = {
  setActiveColumnsGroup({ commit }, [platform, group, val]) {
    commit("SET_ACTIVE_COLUMNS_GROUP", [platform, group, val]);
  },
  setActiveColumns({ commit }, val) {
    commit("SET_ACTIVE_COLUMNS", val);
  }
};
