<template>
  <div class="main-content">
    <v-toolbar color="grey lighten-2" flat>
      <v-row align="center">
        <v-col cols="auto">
          <TableTitle
            :platform="platform"
            :section="table.section"
            :subsection="table.subsection"
            :sectionID="table.sectionID"
          />
        </v-col>
        <v-spacer />
        <v-col class="py-0" cols="auto" justify="end">
          <v-row align="center">
            <v-col cols="auto">
              <LeadTypePicker />
            </v-col>
            <v-col cols="auto">
              <DatePicker />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-container fluid>
      <div class="grey lighten-3">
        <v-row align="end" no-gutters>
          <v-col cols="auto" v-if="typeof table.subsection == 'string'">
            <SubsectionNav :platform="platform" />
          </v-col>
          <v-spacer />
          <v-col cols="auto">
            <v-row no-gutters justify="end">
              <v-col cols="auto" v-if="activeFilters.length > 0" class="pr-3">
                <ActiveFilters />
              </v-col>
            </v-row>
            <v-row no-gutters class="py-1" justify="end">
              <v-col cols="auto" class="pr-3">
                <Download />
              </v-col>
              <v-col cols="auto" class="pr-3">
                <FiltersModal />
              </v-col>
              <v-col cols="auto" class="pr-3">
                <ColumnVisibility @columnVisibility="columnVisibilityUpdated" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-sheet elevation="1">
        <div v-if="table.items === null" class="table-loading">
          <v-icon class="loading-icon" x-large>mdi-loading</v-icon>
        </div>

        <div v-else-if="table.items.length === 0" class="table-loading">
          <h2>Sorry, no results found.</h2>
        </div>

        <v-data-table
          v-else
          :headers="visibleHeaders"
          :items="table.items"
          multi-sort
          id="data-table"
        >
          <template
            v-for="h in visibleHeaders"
            v-slot:[`header.${h.value}`]="{}"
          >
            <v-tooltip v-bind:key="h.text" top v-if="h.tooltip">
              <template v-slot:activator="{ on }">
                <span v-on="on" v-html="h.text"></span>
              </template>
              <div class="text-center"><span v-html="h.tooltip"></span></div>
            </v-tooltip>
            <span v-else v-bind:key="h.text" v-html="h.text"></span>
          </template>

          <template slot="item.account" slot-scope="props">
            <RowTitle
              v-if="tableID === 'accounts'"
              :platform="props.item.platform"
              :to="{
                name: subsectionRouteName,
                params: {
                  section: 'accounts',
                  id: props.item.accountId,
                  subsection: 'campaigns'
                }
              }"
              :title="props.item.account"
            />
            <span v-else>
              {{ props.item.account }}
            </span>
          </template>

          <template slot="item.campaign" slot-scope="props">
            <RowTitle
              v-if="tableType === 'campaigns'"
              :platform="props.item.platform"
              :to="{
                name: subsectionRouteName,
                params: {
                  section: 'campaigns',
                  id: props.item.campaignId,
                  subsection: 'adgroups'
                }
              }"
              :title="props.item.campaign"
              :subtitles="[props.item.account]"
            />
            <span v-else>
              {{ props.item.campaign }}
            </span>
          </template>

          <template slot="item.adgroup" slot-scope="props">
            <RowTitle
              v-if="tableType === 'adgroups'"
              :platform="platform"
              :to="{
                name: subsectionRouteName,
                params: {
                  section: 'adgroups',
                  id: props.item.adgroupId,
                  subsection: getAdgroupSubsection(platform)
                }
              }"
              :title="props.item.adgroup"
              :subtitles="[props.item.account, props.item.campaign]"
            />
            <span v-else>
              {{ props.item.adgroup }}
            </span>
          </template>

          <template slot="item.keyword" slot-scope="props">
            <RowTitle
              v-if="tableID === 'keywords' && platform === 'sem'"
              :platform="props.item.platform"
              :title="props.item.keyword"
              :subtitles="[
                props.item.account,
                props.item.campaign,
                props.item.adgroup
              ]"
            />
            <span v-else>
              {{ props.item.keyword }}
            </span>
          </template>

          <template slot="item.creative" slot-scope="props">
            <span
              class="row-title"
              v-if="tableType === 'creatives' && platform === 'sem'"
            >
              <span class="creative" v-if="getHeadlines(props.item)">
                <span class="creative__headlines">
                  {{ getHeadlines(props.item) }}
                </span>
                <span class="creative__description">{{
                  props.item.description
                }}</span>
                <span class="creative__url">{{
                  props.item.url | prettifyUrls
                }}</span>
              </span>
              <span v-else-if="props.item.campaign === 'Custom Intent Display'">
                Display Ad
              </span>
              <span v-else>RSA</span>
            </span>
            <span v-else>
              {{ props.item.creative }}
            </span>
          </template>

          <template slot="item.impressions" slot-scope="props">
            {{ props.item.impressions | formatNumber }}
          </template>

          <template slot="item.clicks" slot-scope="props">
            {{ props.item.clicks | formatNumber }}
          </template>

          <template slot="item.cost" slot-scope="props">
            {{ props.item.cost | currency }}
          </template>

          <template slot="item.ctr" slot-scope="props">
            {{ props.item.ctr }}%
          </template>

          <template slot="item.mds" slot-scope="props">
            {{ props.item.mds | formatNumber }}
          </template>

          <template slot="item.cpmd" slot-scope="props">
            {{ props.item.cpmd | currency }}
          </template>

          <template slot="item.leads" slot-scope="props">
            {{ props.item.leads | formatNumber }}
          </template>

          <template slot="item.cpl" slot-scope="props">
            {{ props.item.cpl | currency }}
          </template>

          <template slot="item.marketingQualifiedLeads" slot-scope="props">
            {{ props.item.marketingQualifiedLeads | formatNumber }}
          </template>

          <template slot="item.cpmql" slot-scope="props">
            {{ props.item.cpmql | currency }}
          </template>

          <template slot="item.confirmedMesoLeads" slot-scope="props">
            {{ props.item.confirmedMesoLeads | formatNumber }}
          </template>

          <template slot="item.cpcml" slot-scope="props">
            {{ props.item.cpcml | currency }}
          </template>

          <template slot="item.salesQualifiedLeads" slot-scope="props">
            {{ props.item.salesQualifiedLeads | formatNumber }}
          </template>

          <template slot="item.cpsql" slot-scope="props">
            {{ props.item.cpsql | currency }}
          </template>

          <template slot="item.accountSendovers" slot-scope="props">
            {{ props.item.accountSendovers | formatNumber }}
          </template>

          <template slot="item.cpas" slot-scope="props">
            {{ props.item.cpas | currency }}
          </template>

          <template slot="item.accountMeetings" slot-scope="props">
            {{ props.item.accountMeetings | formatNumber }}
          </template>

          <template slot="item.cpam" slot-scope="props">
            {{ props.item.cpam | currency }}
          </template>

          <template slot="item.customers" slot-scope="props">
            {{ props.item.customers | formatNumber }}
          </template>

          <template slot="item.cpcust" slot-scope="props">
            {{ props.item.cpcust | currency }}
          </template>

          <template slot="item.cpc" slot-scope="props">
            {{ props.item.cpc | currency }}
          </template>

          <template slot="item.maxCpc" slot-scope="props">
            {{ props.item.maxCpc | currency }}
          </template>

          <template slot="item.url" slot-scope="props">
            {{ props.item.url | prettifyUrls }}
          </template>

          <template slot="item.cpm" slot-scope="props">
            {{ props.item.cpm | currency }}
          </template>

          <template slot="item.link_clicks" slot-scope="props">
            {{ props.item.link_clicks | formatNumber }}
          </template>

          <template slot="item.post_reactions" slot-scope="props">
            {{ props.item.post_reactions | formatNumber }}
          </template>

          <template slot="item.likes" slot-scope="props">
            {{ props.item.likes | formatNumber }}
          </template>

          <template slot="item.cvr" slot-scope="props">
            {{ props.item.cvr }}%
          </template>

          <template slot="item.md2l" slot-scope="props">
            {{ props.item.md2l }}%
          </template>

          <template slot="item.l2mql" slot-scope="props">
            {{ props.item.l2mql }}%
          </template>

          <template slot="item.mql2cml" slot-scope="props">
            {{ props.item.mql2cml }}%
          </template>

          <template slot="item.cml2sql" slot-scope="props">
            {{ props.item.cml2sql }}%
          </template>

          <template slot="item.sql2as" slot-scope="props">
            {{ props.item.sql2as }}%
          </template>

          <template slot="item.as2am" slot-scope="props">
            {{ props.item.as2am }}%
          </template>

          <template slot="item.am2c" slot-scope="props">
            {{ props.item.am2c }}%
          </template>

          <template slot="item.status" slot-scope="props">
            <v-icon
              v-if="getStatusIcon(props.item.status) !== null"
              class="status-icon"
              small
              :color="getStatusIcon(props.item.status).color"
              >{{ getStatusIcon(props.item.status).icon }}</v-icon
            >
            {{ props.item.status | capitalize }}
          </template>

          <template slot="body.append">
            <tr class="totals">
              <td
                v-for="column in visibleHeaders"
                :key="column.value"
                :class="'text-' + column.align"
              >
                <span v-if="column.total.format === 'integer'">{{
                  total(column.value) | formatNumber
                }}</span>
                <span v-else-if="column.total.format === 'currency'">{{
                  total(column.value) | currency
                }}</span>
                <span v-else-if="column.total.format === 'custom'">{{
                  column.total.output
                }}</span>
                <span v-else>{{ total(column.value) }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TableTitle from "./TableTitle.vue";
import ColumnVisibility from "./ColumnVisibility.vue";
import Download from "./Download.vue";
import FiltersModal from "./FiltersModal.vue";
import RowTitle from "./RowTitle.vue";
import SubsectionNav from "./SubsectionNav.vue";
import DatePicker from "@/components/DatePicker.vue";
import ActiveFilters from "./ActiveFilters.vue";
import LeadTypePicker from "@/components/LeadTypePicker.vue";

function formatNumber(value) {
  if (typeof value !== "number") return "";
  return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function prettifyUrls(url) {
  if (url !== null && url.charAt(0) === "[") {
    return url.slice(2, url.length - 2);
  } else return url;
}

function maxValue(table, column) {
  let max = 0;
  if (table.length) {
    table.forEach(item => {
      let value = parseFloat(item[column]);
      max = value > max ? value : max;
    });
  }
  return max;
}

function columnSum(table, column) {
  let total = null;

  if (table.length) {
    table.forEach(item => {
      total += parseFloat(item[column]);
    });
  }
  return total;
}

function columnPercentage(colA, colB) {
  let total = null;

  if (colA > 0 && colB > 0) {
    total = ((colA / colB) * 100).toFixed(2);
  } else {
    total = 0;
  }
  return total + "%";
}

export default {
  components: {
    TableTitle,
    ColumnVisibility,
    Download,
    FiltersModal,
    RowTitle,
    SubsectionNav,
    DatePicker,
    ActiveFilters,
    LeadTypePicker
  },
  props: ["platform"],
  data() {
    return {
      visibleColumns: null,
      visibleHeaders: []
    };
  },
  watch: {
    visibleColumns() {
      this.visibleHeaders = [];
      this.headers.forEach(header => {
        if (this.visibleColumns.includes(header.text)) {
          this.visibleHeaders.push(header);
        }
      });
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    formatNumber: function(value) {
      return formatNumber(value);
    },
    prettifyUrls: function(url) {
      return prettifyUrls(url);
    }
  },
  methods: {
    getAdgroupSubsection(platform) {
      let subsection = platform === "sem" ? "keywords" : "ads";
      return subsection;
    },
    columnVisibilityUpdated(value) {
      this.visibleColumns = value;
    },
    subsectionChanged(section) {
      this.$emit("subsectionChanged", section);
    },
    total(column) {
      let total = null;

      switch (column) {
        case "impressions":
          total = columnSum(this.table.items, column);
          break;
        case "clicks":
          total = columnSum(this.table.items, column);
          break;
        case "cost":
          total = columnSum(this.table.items, column);
          break;
        case "ctr":
          total = columnPercentage(
            this.total("clicks"),
            this.total("impressions")
          );
          break;
        case "mds":
          total = columnSum(this.table.items, column);
          break;
        case "cpmd":
          total = (this.total("cost") / this.total("mds")).toFixed(2);
          break;
        case "leads":
          total = columnSum(this.table.items, column);
          break;
        case "cpl":
          total = (this.total("cost") / this.total("leads")).toFixed(2);
          break;
        case "marketingQualifiedLeads":
          total = columnSum(this.table.items, column);
          break;
        case "cpmql":
          total = (
            this.total("cost") / this.total("marketingQualifiedLeads")
          ).toFixed(2);
          break;
        case "accounts":
          total = columnSum(this.table.items, column);
          break;
        case "cpa":
          total = (this.total("cost") / this.total("accounts")).toFixed(2);
          break;
        case "confirmedMesoLeads":
          total = columnSum(this.table.items, column);
          break;
        case "cpcml":
          total = (
            this.total("cost") / this.total("confirmedMesoLeads")
          ).toFixed(2);
          break;
        case "salesQualifiedLeads":
          total = columnSum(this.table.items, column);
          break;
        case "cpsql":
          total = (
            this.total("cost") / this.total("salesQualifiedLeads")
          ).toFixed(2);
          break;
        case "accountSendovers":
          total = columnSum(this.table.items, column);
          break;
        case "cpas":
          total = (this.total("cost") / this.total("accountSendovers")).toFixed(
            2
          );
          break;
        case "accountMeetings":
          total = columnSum(this.table.items, column);
          break;
        case "cpam":
          total = (this.total("cost") / this.total("accountMeetings")).toFixed(
            2
          );
          break;
        case "customers":
          total = columnSum(this.table.items, column);
          break;
        case "cpcust":
          total = (this.total("cost") / this.total("customers")).toFixed(2);
          break;
        case "cpc":
          total = (this.total("cost") / this.total("clicks")).toFixed(2);
          break;
        case "maxCpc":
          total = maxValue(this.table.items, column);
          break;
        case "link_clicks":
          total = columnSum(this.table.items, column);
          break;
        case "post_reactions":
          total = columnSum(this.table.items, column);
          break;
        case "likes":
          total = columnSum(this.table.items, column);
          break;
        case "cpm":
          total = columnSum(this.table.items, column);
          break;
        case "md2l":
          total = columnPercentage(this.total("leads"), this.total("mds"));
          break;
        case "cvr":
          total = columnPercentage(this.total("leads"), this.total("clicks"));
          break;
        case "l2mql":
          total = columnPercentage(
            this.total("marketingQualifiedLeads"),
            this.total("leads")
          );
          break;
        case "mql2cml":
          total = columnPercentage(
            this.total("confirmedMesoLeads"),
            this.total("marketingQualifiedLeads")
          );
          break;
        case "cml2sql":
          total = columnPercentage(
            this.total("salesQualifiedLeads"),
            this.total("confirmedMesoLeads")
          );
          break;
        case "sql2as":
          total = columnPercentage(
            this.total("accountSendovers"),
            this.total("salesQualifiedLeads")
          );
          break;
        case "as2am":
          total = columnPercentage(
            this.total("accountMeetings"),
            this.total("accountSendovers")
          );
          break;
        case "am2c":
          total = columnPercentage(
            this.total("customers"),
            this.total("accountMeetings")
          );
          break;
      }
      return total;
    },
    getStatusIcon(status) {
      let icon = null;
      let statusLowercase = status
        .toString()
        .trim()
        .toLowerCase();
      console.log(statusLowercase);
      switch (statusLowercase) {
        case "active":
          icon = {
            color: "#4CAF50",
            icon: "mdi-circle"
          };
          break;
        case "unknown":
          icon = {
            color: "#03A9F4",
            icon: "mdi-help-circle"
          };
          break;
        case "paused":
          icon = {
            color: "#FFC107",
            icon: "mdi-pause-circle"
          };
          break;
        case "removed":
          icon = {
            color: "#F44336",
            icon: "mdi-minus-circle"
          };
          break;
        case "archived":
          icon = {
            color: "#6b6c73",
            icon: "mdi-archive"
          };
          break;
        case "deleted":
          icon = {
            color: "#F44336",
            icon: "mdi-minus-circle"
          };
          break;
        case "disapproved":
          icon = {
            color: "#F44336",
            icon: "mdi-close-circle"
          };
          break;
        case "with issues":
          icon = {
            color: "#FF5722",
            icon: "mdi-alert-rhombus"
          };
          break;
      }
      console.log(icon);
      return icon;
    },
    getHeadlines(item) {
      let headlines = [item.headline1, item.headline2, item.headline3].filter(
        function(e) {
          return e;
        }
      );
      return headlines.join(" | ");
    }
  },
  computed: {
    subsectionRouteName() {
      return this.platform === "facebook"
        ? "FacebookSubsection"
        : "SemSubsection";
    },
    tableID() {
      let id = [this.table.section, this.table.subsection]
        .join(" ")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
      return id;
    },
    tableType() {
      return this.table.subsection ? this.table.subsection : this.table.section;
    },
    headers() {
      return this.getHeaders(this.platform, this.tableType);
    },
    ...mapState(["columnVisibility", "table", "tableHeaders"]),
    ...mapGetters({
      getHeaders: "tableHeaders/getHeaders",
      activeFilters: "table/filters"
    })
  }
};
</script>

<style lang="scss">
.main-content {
  width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  .container {
    max-height: 100%;
    display: grid;
    width: 100%;
    overflow: hidden;
    grid-template-rows: auto 1fr;
  }
  .v-sheet {
    max-height: 100%;
    overflow: hidden;
  }
  .v-data-table {
    max-height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .v-data-table__wrapper {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
}
#data-table {
  thead {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
  }
  tr:not(.totals):hover {
    td {
      background: #f9f9fa;
      &:nth-child(1) {
        background: #f9f9fa;
      }
    }
  }
  td {
    &:nth-child(1) {
      background-color: #fff;
    }
  }
  td,
  th {
    white-space: nowrap;

    &:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 2;

      &:after {
        content: "";
        display: block;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -3px;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.15) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    &:last-child {
      border-right: 0;
    }
  }
  .row-title {
    display: flex;
    justify-content: flex-start;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .v-icon {
      margin-right: 0.5rem;
    }
  }
  .row-title__copy {
    display: flex;
    flex-direction: column;

    small {
      color: #757575;
    }
  }
  th {
    background-color: #ededf0;
    position: relative;
    &:first-child {
      z-index: 3;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: -3px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    span {
      margin-left: 4px;
    }
  }
  tr.totals {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 2;

    td {
      background-color: #ededf0;
      position: relative;
      border-bottom: 0;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        top: -3px;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.15) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      &:first-child {
        position: sticky;
        z-index: 3;
      }
    }
  }
}
.status-icon {
  margin-right: 0.25rem;
}
.creative {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.creative__headlines {
  color: blue;
  font-size: 13px;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 30rem;
}
.creative__description {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 11px;
}
.creative__url {
  color: #393;
  font-size: 11px;
  max-width: 30rem;
  word-break: break-all;
  white-space: pre-wrap;
}

.table-loading {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
