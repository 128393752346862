import APIService from "@/services/APIService.js";

export const namespaced = true;

export const state = {
  name: null,
  section: null,
  sectionID: null,
  subsection: null,
  platform: null,
  items: null,
  details: null,
  filters: [],
  link: null,
  dashboard: null,
  status: null
};

function getKey(section) {
  switch (section) {
    case "accounts":
      return "accountId";
    case "campaigns":
      return "campaignId";
    case "adgroups":
      return "adgroupId";
    default:
      return null;
  }
}

export const mutations = {
  SET_STATUS(state, status) {
    state.status = status;
  },
  SET_DASHBOARD(state, data) {
    state.dashboard = data;
  },
  CLEAR_DASHBOARD(state) {
    state.dashboard = null;
  },
  CLEAR_TABLE(state) {
    state.name = null;
    state.section = null;
    state.sectionID = null;
    state.subsection = null;
    state.items = null;
    state.details = null;
    state.filters = [];
    state.link = null;
  },
  CLEAR_ITEMS(state) {
    state.items = null;
  },
  CLEAR_DETAILS(state) {
    state.details = null;
  },
  CLEAR_FITLERS(state) {
    state.filters = [];
  },
  SET_PLATFORM(state, platform) {
    state.platform = platform;
  },
  SET_SECTION(state, section) {
    state.section = section;
  },
  SET_SECTION_ID(state, id) {
    state.sectionID = id;
  },
  SET_SUBSECTION(state, subsection) {
    state.subsection = subsection;
  },
  SET_NAME(state, name) {
    state.name = name;
  },
  SET_ITEMS(state, data) {
    state.items = data;
  },
  SET_DETAILS(state, data) {
    state.details = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
    sessionStorage.setItem("tableFilters", JSON.stringify(state.filters));
  },
  SET_LINK(state, value) {
    state.link = value;
  },
  REMOVE_FILTER_BY_INDEX(state, index) {
    state.filters = state.filters.splice(index, 1);
    sessionStorage.setItem("tableFilters", JSON.stringify(state.filters));
  }
};

export const actions = {
  clearTable({ commit }) {
    commit("CLEAR_TABLE");
  },
  clearDetails({ commit }) {
    commit("CLEAR_DETAILS");
  },
  clearItems({ commit }) {
    commit("CLEAR_ITEMS");
  },
  clearFilters({ commit }) {
    commit("CLEAR_FITLERS");
  },
  setPlatform({ commit }, platform) {
    commit("SET_PLATFORM", platform);
  },
  setSection({ commit }, section) {
    commit("SET_SECTION", section);
  },
  setSectionID({ commit }, id) {
    commit("SET_SECTION_ID", id);
  },
  setSubsection({ commit }, subsection) {
    commit("SET_SUBSECTION", subsection);
  },
  setName({ commit }, name) {
    commit("SET_NAME", name);
  },
  setFilters({ commit }, data) {
    commit("SET_FILTERS", data);
  },
  setLink({ commit }, value) {
    commit("SET_LINK", value);
  },
  removeFilterByIndex({ commit, state }, index) {
    let filters = state.filters;
    filters.splice(index, 1);
    commit("SET_FILTERS", filters);
    sessionStorage.setItem("tableFilters", JSON.stringify(filters));
  },
  fetchTable({ commit, state, rootState, rootGetters }) {
    let apiCall = null;
    let type = state.subsection !== null ? state.subsection : state.section;
    state.items = null;

    switch (state.platform) {
      case "sem":
        switch (type) {
          case "accounts":
            apiCall = APIService.getSemAccounts();
            break;
          case "campaigns":
            apiCall = APIService.getSemCampaigns();
            break;
          case "adgroups":
            apiCall = APIService.getSemAdgroups();
            break;
          case "keywords":
            apiCall = APIService.getSemKeywords();
            break;
          case "creatives":
            apiCall = APIService.getSemCreatives();
            break;
        }
        break;
      case "facebook":
        switch (type) {
          case "accounts":
            apiCall = APIService.getFacebookAccounts();
            break;
          case "campaigns":
            apiCall = APIService.getFacebookCampaigns();
            break;
          case "adgroups":
            apiCall = APIService.getFacebookAdgroups();
            break;
          case "ads":
            apiCall = APIService.getFacebookAds();
            break;
        }
        break;
    }

    if (apiCall !== null) {
      apiCall
        .then(response => {
          commit("SET_ITEMS", response.data);

          let hash = {
            date: {
              start: rootState.dates.initial.start,
              end: rootState.dates.initial.end
            }
          };

          if (state.filters.length) {
            hash["filters"] = state.filters;
          }

          let leadTypeQueryParams =
            rootGetters["leadTypes/getActiveQueryParams"];

          if (leadTypeQueryParams) {
            hash["lead_types"] = leadTypeQueryParams;
          }

          history.pushState(
            {},
            null,
            window.location.pathname + "#" + JSON.stringify(hash)
          );
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  fetchDetails({ commit, state }) {
    commit("CLEAR_DETAILS");

    let filters = {};
    filters[getKey(state.section)] = state.sectionID;

    APIService.getRow(state.platform, JSON.stringify(filters))
      .then(response => {
        commit("SET_DETAILS", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },
  fetchDashboard({ commit, rootState, rootGetters }) {
    commit("CLEAR_DASHBOARD");

    let filters = {};

    APIService.getDashboard(JSON.stringify(filters))
      .then(response => {
        commit("SET_DASHBOARD", response.data);

        let hash = {
          date: {
            start: rootState.dates.initial.start,
            end: rootState.dates.initial.end
          }
        };

        let leadTypeQueryParams = rootGetters["leadTypes/getActiveQueryParams"];

        if (leadTypeQueryParams) {
          hash["lead_types"] = leadTypeQueryParams;
        }

        history.pushState(
          {},
          null,
          window.location.pathname + "#" + JSON.stringify(hash)
        );
      })
      .catch(error => {
        console.log(error);
      });
  },
  fetchStatus({ commit }) {
    APIService.getStatus()
      .then(response => {
        commit("SET_STATUS", response.data.last_updated);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

export const getters = {
  name: state => state.name,
  platform: state => state.platform,
  section: state => state.section,
  sectionID: state => state.sectionID,
  subsection: state => state.subsection,
  items: state => state.items,
  details: state => state.details,
  filters: state => state.filters,
  dashboard: state => state.dashboard
};
