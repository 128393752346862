import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: colors.indigo,
        secondary: "#847dd4",
        background: colors.grey.lighten5
      }
    }
  }
};

export default new Vuetify(opts);
