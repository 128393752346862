import APIService from "@/services/APIService.js";
export const namespaced = true;

export const state = {
  active: [],
  types: []
};

export const mutations = {
  SET_ACTIVE(state, types) {
    state.active = types;
    sessionStorage.setItem("activeLeadTypes", JSON.stringify(types));
  },
  SET_TYPES(state, types) {
    state.types = types;
    sessionStorage.setItem("leadTypes", JSON.stringify(types));
  }
};

export const actions = {
  setActiveLeadTypes({ commit }, types) {
    commit("SET_ACTIVE", types);
  },
  fetchLeadTypes({ commit }) {
    APIService.getLeadTypes()
      .then(response => {
        commit("SET_TYPES", response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
};

export const getters = {
  getActive: state => {
    if (state.active.length === 0) {
      state.active = [...state.types];
    }
    return state.active;
  },
  getActiveQueryParams: state => {
    if (
      state.active.length === 0 ||
      state.active.length === state.types.length
    ) {
      return false;
    } else {
      return state.active;
    }
  }
};
