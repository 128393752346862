<template>
  <Table :platform="this.platform" />
</template>

<script>
import store from "@/store/";
import Table from "@/components/table/Table.vue";
import { mapState } from "vuex";

export default {
  components: { Table },
  computed: {
    platform() {
      return this.$route.meta.platform;
    },
    ...mapState(["table"])
  },
  methods: {
    setTableParams(platform, section, sectionID, subsection) {
      store.dispatch("table/setPlatform", platform);
      store.dispatch("table/setSection", section);
      store.dispatch("table/setSectionID", sectionID || null);
      store.dispatch("table/setSubsection", subsection || null);
    },
    fetchTable() {
      store.dispatch("table/fetchTable");
    },
    fetchDetails() {
      if (this.table.sectionID) {
        store.dispatch("table/fetchDetails");
      } else {
        store.dispatch("table/clearDetails");
      }
    }
  },
  created() {
    this.setTableParams(
      this.$route.meta.platform,
      this.$route.params.section,
      this.$route.params.id,
      this.$route.params.subsection
    );
    this.setHashParams();
    this.fetchDetails();
    this.fetchTable();
  },
  beforeRouteUpdate(to, from, next) {
    if (to !== from) {
      store.dispatch("table/clearFilters");
      this.setTableParams(
        to.meta.platform,
        to.params.section,
        to.params.id,
        to.params.subsection
      );
      this.fetchDetails();
      this.fetchTable();
      next();
    } else {
      next(false);
    }
  },
  beforeRouteLeave(to, from, next) {
    store.dispatch("table/clearFilters");
    this.setTableParams(
      to.meta.platform,
      to.params.section,
      to.params.id,
      to.params.subsection
    );
    if (to.params.section && to.params.id) {
      this.fetchDetails();
    }
    this.fetchTable();
    next();
  }
};
</script>
