import Vue from "vue";
import Vuex from "vuex";
import * as columnVisibility from "@/store/modules/columnVisibility.js";
import * as dates from "@/store/modules/dates.js";
import * as leadTypes from "@/store/modules/leadTypes.js";
import * as table from "@/store/modules/table.js";
import * as tableHeaders from "@/store/modules/tableHeaders.js";
import * as user from "@/store/modules/user.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    columnVisibility,
    dates,
    leadTypes,
    table,
    tableHeaders,
    user
  }
});
