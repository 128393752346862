import axios from "axios";

export default {
  getUser(authCode) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache"
      },
      baseURL: process.env.VUE_APP_API_AUTH,
      data: {
        code: authCode,
        redirectURI: process.env.VUE_APP_AUTH_REDIRECT
      }
    };
    return axios(options);
  }
};
