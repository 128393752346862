import moment from "moment";

export const namespaced = true;

function dateParse(date) {
  return moment(date).format("YYYY-MM-DD");
}

function today() {
  let today = new Date().toISOString().substr(0, 10);
  return dateParse(today);
}

function yesterday() {
  let yesterday = moment(today()).subtract(1, "days");
  return dateParse(yesterday);
}

// function dateFormat(date) {
//   return moment(date).format("MM/DD/YYYY");
// }

export const state = {
  initial: {
    preset: "Past 30 Days",
    start: dateParse(moment(yesterday()).subtract(1, "months")),
    end: yesterday()
  },
  compare: {
    enabled: false,
    start: null,
    end: null
  },
  earliest: "2017-01-01",
  today: today(),
  yesterday: yesterday()
};

export const mutations = {
  SET_INITIAL(state, dates) {
    state.initial.start = dateParse(dates[0]);
    state.initial.end = dateParse(dates[1]);
    sessionStorage.setItem("datesInitial", JSON.stringify(dates));
  },
  SET_INITIAL_PRESET(state, value) {
    state.initial.preset = value;
    sessionStorage.setItem("datesInitialPreset", JSON.stringify(value));
  },
  SET_COMPARE(state, dates) {
    if (Array.isArray(dates)) {
      state.compare.enabled = true;
      state.compare.start = dateParse(dates[0]);
      state.compare.end = dateParse(dates[1]);
    } else {
      state.compare.enabled = false;
      state.compare.start = null;
      state.compare.end = null;
    }
    sessionStorage.setItem("datesCompare", JSON.stringify(dates));
  }
};

export const actions = {
  setInitial({ commit }, dates) {
    commit("SET_INITIAL", dates);
  },
  setInitialPreset({ commit }, value) {
    commit("SET_INITIAL_PRESET", value);
  },
  setCompare({ commit }, dates) {
    commit("SET_COMPARE", dates);
  }
};
