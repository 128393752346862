<template>
  <div class="header">
    <v-icon v-if="platformIcon" class="mr-2" :color="platformIcon.color">{{
      platformIcon.icon
    }}</v-icon>
    <ul v-if="sectionID === null">
      <li>
        <router-link
          :to="{
            name: sectionRouteName,
            params: {
              section: 'accounts'
            }
          }"
        >
          {{ platformName }}
        </router-link>
      </li>
      <li>{{ capitalize(section) }}</li>
    </ul>
    <ul v-else>
      <li>
        <router-link
          :to="{
            name: sectionRouteName,
            params: {
              section: 'accounts'
            }
          }"
        >
          {{ platformName }}
        </router-link>
      </li>
      <li v-if="table.details !== null">
        <router-link
          :to="{
            name: subsectionRouteName,
            params: {
              section: 'accounts',
              id: table.details.account_id,
              subsection: 'campaigns'
            }
          }"
        >
          {{ table.details.account }}
        </router-link>
      </li>
      <li
        v-if="
          table.details !== null &&
            ['campaigns', 'adgroups'].includes(table.section)
        "
      >
        <router-link
          :to="{
            name: subsectionRouteName,
            params: {
              section: 'campaigns',
              id: table.details.campaign_id,
              subsection: 'adgroups'
            }
          }"
        >
          {{ table.details.campaign }}
        </router-link>
      </li>
      <li v-if="table.details !== null && ['adgroups'].includes(table.section)">
        <router-link
          :to="{
            name: subsectionRouteName,
            params: {
              section: 'adgroups',
              id: table.details.adgroupId,
              subsection: 'keywords'
            }
          }"
        >
          {{ table.details.adgroup }}
        </router-link>
      </li>
    </ul>

    <v-icon v-if="table.details === null && sectionID" class="loading-icon ml-4"
      >mdi-loading</v-icon
    >
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["platform", "section", "subsection", "sectionID"],
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  computed: {
    sectionRouteName() {
      return this.capitalize(this.platform) + "Section";
    },
    subsectionRouteName() {
      return this.capitalize(this.platform) + "Subsection";
    },
    platformName() {
      let platformId = this.platform;
      let name = null;

      switch (platformId) {
        case "sem":
          name = "SEM";
          break;
        default:
          name = this.capitalize(platformId);
      }

      return name;
    },
    platformIcon() {
      let icon = null;
      switch (this.platform) {
        case "sem":
          icon = {
            icon: "mdi-search-web",
            color: "#3F51B5"
          };
          break;

        case "facebook":
          icon = {
            icon: "mdi-facebook",
            color: "#4267B2"
          };
          break;
      }
      return icon;
    },
    ...mapState(["table"])
  }
};
</script>

<style lang="scss">
.v-application {
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .header__icon {
    margin-right: 0.75rem;
  }
  .header {
    a {
      text-decoration-skip-ink: auto;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.87);
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      padding-left: 0;
    }
    li {
      display: inline-block;
      font-size: 1rem;
      line-height: 1;

      &:after {
        content: "|";
        display: inline-block;
        padding: 0 1rem;
      }

      &:last-child {
        font-weight: bold;
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
