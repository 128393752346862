<template>
  <div class="row-title">
    <v-icon v-if="platform" :color="getColor(platform)">
      {{ getIcon(platform) }}
    </v-icon>
    <div class="row-title__copy">
      <router-link v-if="to" :to="to">{{ title }}</router-link>
      <span v-else>{{ title }}</span>
      <small v-for="(subtitle, index) in subtitles" :key="index">{{
        subtitle
      }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    platform: String,
    to: Object,
    subtitles: {
      type: Array,
      value: []
    },
    title: String
  },
  methods: {
    getColor(platform) {
      let color = null;
      switch (platform) {
        case "Bing":
          color = "#008272";
          break;
        case "AdWords":
          color = "#4287d2";
          break;
        case "Facebook":
          color = "#4267B2";
          break;
      }
      return color;
    },
    getIcon(platform) {
      let icon = null;
      switch (platform) {
        case "Bing":
          icon = "mdi-microsoft-bing";
          break;
        case "AdWords":
          icon = "mdi-google-ads";
          break;
        case "Facebook":
          icon = "mdi-facebook";
          break;
      }
      return icon;
    }
  }
};
</script>

<style></style>
