import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCurrencyFilter from "vue-currency-filter";
import semver from "semver";

import Default from "./layouts/Default.vue";
import SignIn from "./layouts/SignIn.vue";
import Dashboard from "./layouts/Dashboard.vue";

Vue.component("Default-layout", Default);
Vue.component("SignIn-layout", SignIn);
Vue.component("Dashboard-layout", Dashboard);

if (!localStorage.user) {
  router.push({ name: "SignIn" });
}

Vue.config.productionTip = false;

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true
});

Vue.mixin({
  methods: {
    setHashParams() {
      let hash = window.location.hash.substring(1);

      if (hash) {
        let parsedHash = JSON.parse(decodeURI(hash));

        if (parsedHash.filters) {
          this.$store.dispatch("table/setFilters", parsedHash.filters);
        }
        if (parsedHash.date) {
          this.$store.dispatch("dates/setInitial", parsedHash.date);
        }
        if (parsedHash.lead_types) {
          this.$store.dispatch(
            "leadTypes/setActiveLeadTypes",
            parsedHash.lead_types
          );
        }
      }
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    const userVersion = localStorage.getItem("version")
      ? localStorage.getItem("version")
      : "0.0.1";
    const appVersion = "2.0.0";

    if (semver.gt(appVersion, userVersion)) {
      localStorage.clear();
      localStorage.setItem("version", appVersion);
      this.$store.dispatch("user/logout");
    }
  },
  created() {
    const userString = localStorage.getItem("user");
    if (userString !== null) {
      this.$store.dispatch("user/setUser", JSON.parse(userString));
    }

    const columnVisibilityString = localStorage.getItem("columnVisibility");
    if (columnVisibilityString !== null) {
      this.$store.dispatch(
        "columnVisibility/setActiveColumns",
        JSON.parse(columnVisibilityString)
      );
    }

    const datesInitial = sessionStorage.getItem("datesInitial");
    if (datesInitial !== null) {
      this.$store.dispatch("dates/setInitial", JSON.parse(datesInitial));
    }

    const activeLeadTypes = sessionStorage.getItem("activeLeadTypes");
    if (activeLeadTypes !== null) {
      this.$store.dispatch(
        "leadTypes/setActiveLeadTypes",
        JSON.parse(activeLeadTypes)
      );
    }
  },
  render: h => h(App)
}).$mount("#app");
